<template>
  <div>
    <el-row
      :gutter="10"
      type="flex"
      justify="center"
      class="mb-6"
    >
      <el-col :span="16">
        <slot name="campaignName"></slot>
      </el-col>
      <el-col :span="8"
        ><btn-icons
          :selectList="selectList"
          @editName="editName"
          @editDate="editDateShowClick"
          @editStatus="editStatus"
        ></btn-icons
      ></el-col>
    </el-row>

    <el-table
      :data="tableData"
      border
      :tree-props="{ children: 'ads' }"
      :show-summary="true"
      sum-text=" "
      :summary-method="getSummaries"
      row-key="index"
      @selection-change="selectAds"
    >
      <el-table-column
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="广告组名称"
        min-width="220px"
      >
        <template slot-scope="{ row, $index }">
          <el-form
            :ref="'formName' + $index"
            :model="row"
            label-width="0"
            :key="row.index"
          >
            <el-form-item
              label=""
              prop="name"
              :rules="[
                {
                  validator: (rule, value, callback) => validatorName(rule, value, callback, row),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <row-name
                v-model="row"
                @input="emitInput"
              ></row-name>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="广告组状态"
        width="320"
      >
        <template slot-scope="{ row }">
          <el-radio-group
            v-model="row.status"
            v-if="row.level == 'adset'"
            @input="emitInput"
          >
            <el-radio label="">默认</el-radio>
            <el-radio
              :label="item.value"
              v-for="item in enumConstants.statusList"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="竞价策略"
        width="260"
      >
        <template slot-scope="{ row, $index }">
          <el-form
            :ref="'formBid' + $index"
            :model="row"
            label-width="0"
          >
            <el-form-item
              label=""
              prop="bidAmount"
              :rules="[
                {
                  validator: (rule, value, callback) => validatorBid(rule, value, callback, row),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <row-bid
                v-model="row"
                :bidStrategys="enumConstants.bidStrategy"
                v-if="row.level == 'adset'"
                @input="emitInput"
              ></row-bid>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-form> -->
    <!-- 批量修改名称 -->
    <edit-name
      :level="editNameLevel"
      :editNameShow.sync="editNameShow"
      @setName="setName"
    ></edit-name>
    <!-- 批量编辑排期 -->
    <edit-date
      :editDateShow.sync="editDateShow"
      @setDate="setDate"
    ></edit-date>
  </div>
</template>
<script>
import moment from 'moment';
// import { budgetTypes, bidStrategys } from './index.js';
import rowName from './tableComponents/rowName.vue';
import rowBudget from './tableComponents/rowBudget.vue';
import rowBid from './tableComponents/rowBid.vue';
import date from './tableComponents/date.vue';
import btnIcons from './tableComponents/btnIcons.vue';
import editName from './tableComponents/editName.vue';
import editDate from './tableComponents/editDate.vue';
export default {
  props: {
    value: {
      type: Array,
      default: [],
    },
    enumConstants: {
      type: Object,
      default: () => {},
    },
    currency: String,
    checkData: Object,
  },
  components: {
    rowName,
    rowBudget,
    rowBid,
    date,
    btnIcons,
    editName,
    editDate,
  },
  data() {
    return {
      selectList: [],
      editNameLevel: 'adset',
      editDateShow: false,
      editNameShow: false,
      form: {},
    };
  },
  computed: {
    tableData: {
      get() {
        let list = JSON.parse(JSON.stringify(this.value));
        list = list.map((v, index) => {
          v.index = index + 1;
          v.status = v.status || '';
          v.level = 'adset';
          v.ads = v.ads.map((ad, ind) => {
            ad.index = v.index + '_' + ind;
            ad.status = ad.status || '';
            ad.level = 'ad';
            return ad;
          });
          return v;
        });
        return list;
      },
      set(value) {
        console.log(this.tableData);
      },
    },
  },
  methods: {
    // 验证名称
    validatorName(rule, value, callback, row) {
      console.log(value);
      if (!value) {
        return callback('名称不能为空');
      }
      if (row.level == 'ad') {
        if (value.length > 375) {
          return callback('广告名称长度不能超过375字符');
        }
        callback();
      }
      callback();
    },
    // 总预算最小值
    getBudgetMin(row) {
      let start = moment(row.startTime).format('YYYY-MM-DD');
      let end = moment(row.endTime).format('YYYY-MM-DD');
      let days = moment(end).diff(start, 'days');
      return this.checkData.adsetBudgetMin * (days + 1);
    },
    // 验证预算
    validatorBudget(rule, value, callback, row) {
      if (row.level == 'ad') {
        callback();
      } else {
        if (!value) {
          return callback('预算不能为空');
        }
        if (row.budgetType == 'LIFETIME_BUDGET') {
          if (row.startTime && row.endTime && value < this.getBudgetMin(row)) {
            return callback(`预算在${this.getBudgetMin(row)}～${this.checkData.adsetBudgetMax}区间`);
          } else {
            callback();
          }
        } else if (value && (value < this.checkData.adsetBudgetMin || value > this.checkData.adsetBudgetMax)) {
          return callback(`预算在${this.checkData.adsetBudgetMin}～${this.checkData.adsetBudgetMax}区间`);
        }
        callback();
      }
    },
    // 验证出价
    validatorBid(rule, value, callback, row) {
      if (row.level == 'ad') {
        callback();
      } else {
        if (row.bidStrategy == 'AUTO') {
          callback();
        } else {
          if (value && (value < this.checkData.bidMin || value > this.checkData.bidMax)) {
            return callback(`出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`);
          } else if (!value) {
            return callback(`出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`);
          }
          callback();
        }
      }
    },
    // 获取预算类型的名称
    setBudgetName(budgetType) {
      return budgetTypes.find((v) => v.value == budgetType)?.label;
    },
    // 获取出价类型的名称
    setBidName(bidStrategy) {
      return bidStrategys.find((v) => v.value == bidStrategy)?.label;
    },
    // 表格合计方法
    getSummaries() {
      const sums = [];
      sums[1] = `共生成个${this.tableData.length}广告组`;
      return sums;
    },
    // 选择广告
    selectAds(v) {
      this.selectList = v.map((item) => item.index);
    },
    // 点击批量编辑名称按钮
    editName(level) {
      this.editNameLevel = level;
      this.editNameShow = true;
    },
    // 点击批量编辑排期
    editDateShowClick() {
      this.editDateShow = true;
    },
    // 点击开启或关闭
    editStatus(status) {
      this.tableData = this.tableData.map((item) => {
        if (this.selectList.includes(item.index)) {
          item.status = status;
        }
        return item;
      });
      this.emitInput();
    },
    // 设置名称
    setName(name, level) {
      this.tableData = this.tableData.map((item) => {
        if (this.selectList.includes(item.index)) {
          if (level == 'adset') {
            item.name = name;
          } else {
            item.ads = item.ads.map((v) => {
              v.name = name;
              return v;
            });
          }
        }
        return item;
      });
      this.emitInput();
    },
    // 设置排期
    setDate(dateObj) {
      console.log(dateObj);
      this.tableData = this.tableData.map((item) => {
        if (this.selectList.includes(item.index)) {
          item.startTime = dateObj.startTime;
          item.endTime = dateObj.endTime;
        }
        console.log(item);
        return item;
      });
      this.emitInput();
    },
    // 对外验证
    validate() {
      let list = [];
      Object.keys(this.$refs).forEach((key) => {
        if (key.indexOf('formName') > -1 || key.indexOf('formBudget') > -1 || key.indexOf('formBid') > -1) {
          list.push(this.$refs[key].validate());
        }
        if (key.indexOf('formDate') > -1) {
          list.push(this.$refs[key].$refs.form.validate());
        }
      });
      list = list.filter((v) => v);
      return Promise.all(list);
    },
    emitInput() {
      console.log(this.tableData);
      this.$emit('input', this.tableData);
    },
  },
};
</script>
<style lang="scss" scoped></style>
