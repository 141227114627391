<template>
  <div>
    <div class="bg-[#fff] mb-6 p-4">
      <p class="font-lg font-bold py-3">预算&排期</p>
      <el-form
        ref="form"
        :model="formData"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item
          label="系列预算"
          prop="budgetAmount"
        >
          <el-input
            v-model="formData.budgetAmount"
            style="width: 320px"
            type="number"
            placeholder="请输入预算金额"
            @blur="changeBudget"
            >" >
            <el-select
              v-model="formData.campaignBudgetType"
              placeholder=""
              slot="prepend"
              style="width: 120px"
              @change="setTableData('campaignBudgetType', formData.campaignBudgetType)"
            >
              <el-option
                v-for="item in enumConstants.budgetTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span slot="append">{{ currency }}</span>
          </el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item
            label="组排期"
            prop="startDate"
          >
            <el-date-picker
              v-model="formData.startDate"
              type="datetime"
              placeholder="开始时间"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="changeStartTime"
            ></el-date-picker>
          </el-form-item>
          <span class="mx-2">~</span>
          <el-form-item
            label=""
            label-width="0"
            prop="endDate"
          >
            <el-date-picker
              v-model="formData.endDate"
              type="datetime"
              placeholder="结束时间"
              @change="selectEndTime"
              :picker-options="pickerOptions1"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </div>

        <el-form-item
          label="竞价策略"
          prop="bidStrategy"
        >
          <el-radio-group
            v-model="formData.bidStrategy"
            @change="selectBid"
          >
            <el-radio
              :label="item.value"
              v-for="item in enumConstants.bidStrategy"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          v-if="formData.bidStrategy != 'AUTO'"
          prop="bidAmount"
        >
          <el-input
            v-model="formData.bidAmount"
            placeholder="请输入出价金额"
            style="width: 240px"
            @blur="changeBid"
            type="number"
          >
            <span slot="append">{{ currency }}/转化</span>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="bg-[#fff] p-4">
      <p class="font-lg font-bold py-3">投放预览</p>
      <split-table
        v-model="splitTableMsg.adGroups"
        :enumConstants="enumConstants"
        :currency="currency"
        v-loading="splitViewLoading"
        :checkData="checkData"
        :getBudgetMin="getBudgetMin"
        ref="splitTable"
      >
        <template slot="campaignName">
          <div class="campaignName">
            <el-form
              ref="campaignNameForm"
              :model="splitTableMsg"
              label-width="120px"
              class="w-full"
            >
              <el-form-item
                label="广告系列名称："
                prop="name"
                :rules="[{ validator: validatorName, trigger: ['blur', 'change'] }]"
              >
                <el-input
                  v-model="splitTableMsg.name"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </split-table>
    </div>
    <config-upload
      :creatFormShow.sync="creatFormShow"
      @creatAd="creatAd"
    ></config-upload>
  </div>
</template>
<script>
import splitTable from './splitTable.vue';
import { createPreview, createCampaign } from '@/api/google/create';
import configUpload from '../configUpload/index.vue';
import moment from 'moment';
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import { budgetTypes, bidStrategys } from './index';
export default {
  props: {
    value: Object,
    enumConstants: Object,
    allAdAccountId: Array,
    params: Object,
    currentAccount: Object,
  },
  data() {
    return {
      formData: {},
      splitTableMsg: {
        name: '',
        adGroups: [],
      },
      checkData: {
        adsetBudgetMin: 5,
        adsetBudgetMax: 10000000,
        bidMin: 0.01,
        bidMax: 1000000,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
      splitViewLoading: false,
      creatFormShow: false,
      // 取消上一次请求
      source: null,
      budgetTypes,
      bidStrategys,
    };
  },
  components: {
    splitTable,
    configUpload,
  },
  computed: {
    ...mapState('user', ['userInfo']),
    rules() {
      return {
        budgetAmount: [
          {
            validator: (rule, value, callback) => {
              if (!value) callback('预算不能为空');
              if (this.formData.campaignBudgetType == 'LIFE_TIME') {
                if (this.formData.startDate && this.formData.endDate && value < this.getBudgetMin()) {
                  return callback(`预算在${this.getBudgetMin()}～${this.checkData.adsetBudgetMax}区间`);
                }
              } else if (value && (value < this.checkData.adsetBudgetMin || value > this.checkData.adsetBudgetMax)) {
                return callback(`预算在${this.checkData.adsetBudgetMin}～${this.checkData.adsetBudgetMax}区间`);
              }
              callback();
            },
            required: true,
            trigger: ['blur', 'change'],
          },
        ],
        startDate: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
        endDate: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.campaignBudgetType == 'LIFE_TIME') {
                if (!value) {
                  callback('预算类型是总预算的时候,结束时间必填');
                }
                if (new Date(value).getTime() < new Date(this.formData.startDate).getTime()) {
                  return callback('结束时间不能小于开始时间');
                }
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        bidAmount: [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.checkData.bidMin || value > this.checkData.bidMax)) {
                return callback(true);
              } else if (!value) {
                return callback(true);
              }
              callback();
            },
            message: `出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`,
            trigger: ['blur', 'change'],
          },
        ],
        bidStrategy: [{ required: true, message: '请选择竞价类型', trigger: ['change', 'blur'] }],
      };
    },
    activeAccount() {
      return this.allAdAccountId.find((v) => v.accountId == this.params.adAccountId) || {};
    },
    currency() {
      return this.currentAccount.currency || 'USD';
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          console.log(val);
          let { budgetAmount, campaignBudgetType, bidStrategy, bidAmount, startDate, endDate, ...other } = this.value;
          this.formData = { budgetAmount, campaignBudgetType, bidStrategy, bidAmount, startDate, endDate };
        }
      },
    },
  },
  methods: {
    intersectionByKey(arrays, key) {
      if (arrays.length === 0) return [];

      // Start with the first array's keys as a Set for comparison
      let result = arrays[0].map((item) => item[key]);

      // Reduce to find the intersection
      for (let i = 1; i < arrays.length; i++) {
        const currentKeys = new Set(arrays[i].map((item) => item[key]));
        result = result.filter((value) => currentKeys.has(value));
      }

      // Filter original objects based on the intersected keys
      return arrays.map((array) => array.filter((item) => result.includes(item[key])))[0];
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.formData.startDate).getTime();
    },
    // 根据结束日期，计算最小预算
    getBudgetMin() {
      let start = moment(this.formData.startDate).format('YYYY-MM-DD');
      let end = moment(this.formData.endDate).format('YYYY-MM-DD');
      let days = moment(end).diff(start, 'days');
      return this.checkData.adsetBudgetMin * (days + 1);
    },
    // 获取校验规则
    // getConfigCheck() {
    //   queryConfigDataCheck({ platform: 'google', currency: this.activeAccount?.currencyCode || 'USD' }).then((res) => {
    //     console.log(res);
    //     this.checkData = res.data;
    //   });
    // },
    // 修改预算
    changeBudget(v) {
      console.log(v);
      this.formData.budgetAmount = (this.formData.budgetAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('budgetAmount', this.formData.budgetAmount);
    },
    // 修改出价
    changeBid(v) {
      this.formData.bidAmount = (this.formData.bidAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('bidAmount', this.formData.bidAmount);
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 修改开始时间
    changeStartTime() {},
    // 总预算设置结束日期的时候校验预算
    selectEndTime() {
      this.$refs.form.validateField('budgetAmount');
      //   this.splitView();
    },
    // 选择竞价策略
    selectBid(v) {
      if (v == 'AUTO') {
        this.formData.bidAmount = null;
        this.setTableData('bidAmount', null);
      }
      //   this.splitView();
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 对外验证
    validate() {
      let list = [];
      list.push(this.$refs.form.validate());
      return Promise.all(list);
    },
    cancelQuest() {
      if (typeof this.source === 'function') {
        // console.log('this.source',this.source)
        this.source(); //取消请求
      }
    },
    splitView() {
      this.$emit('input', { ...this.value, ...this.formData });
      this.$refs.form.validate((validate) => {
        if (validate) {
          this.getSplitTableView();
        }
      });
    },
    // 处理受众
    setInterest(targeting) {
      let list = targeting.map((v) => {
        v.geos = v.geos.map((geo) => {
          if (geo.countryCode) return geo;
          return { countryCode: geo };
        });
        v.interests = [
          {
            categoryId: Array.from(new Set([].concat(...Object.values(v.interests)))),
            operation: 'INCLUDE',
          },
        ];
        v.segments = v.segments.filter((segments) => {
          if (segments.segmentId.length) {
            return segments;
          }
        });
        return v;
      });
      return list;
    },
    // 设置表格单项数据
    setTableData(key, value) {
      this.$emit('input', { ...this.value, ...this.formData });
      this.$refs.form.validate((validate) => {
        if (validate) {
          this.splitTableMsg.adGroups.forEach((group) => {
            group[key] = value;
          });
        }
      });
    },
    // 校验素材名字
    splitStringByMultipleDelimiters(str) {
      // 使用正则表达式匹配所有 -、* 或 # 字符，并启用全局标志
      const delimiters = /[-*#]/g;
      // 使用字符串的 split 方法，但传入一个正则表达式作为分隔符
      // 注意：split 方法会将分隔符本身从结果数组中排除
      return str.split(delimiters);
    },
    // 重置素材名字
    formatterName(str, newmd5) {
      const strList = str.split('.');
      if (strList.length === 2 && newmd5) {
        const nameSplitList = this.splitStringByMultipleDelimiters(strList[0]);
        // 符合规则
        if (nameSplitList.length === 3) {
          const timeStamp = new Date().getTime();
          return `${nameSplitList[0]}#${nameSplitList[1]}#${newmd5}`;
        } else if (nameSplitList.length > 3) {
          let name = '';
          let list = nameSplitList.slice(0, 2);
          //   nameSplitList.forEach((item, index) => {
          //     if (index === 0) {
          //       name = item;
          //     } else if (index > 0 && index !== 2 && index !== nameSplitList.length - 1) {
          //       name = name + '#' + item;
          //     } else if (index === 2) {
          //       name = name + '#' + newmd5;
          //     } else {
          //       //   const timeStamp = new Date().getTime();
          //       //   name = name + '#' + item;
          //     }
          //   });
          return list.join('#') + '#' + newmd5;
          //   return `${name}.${strList[1]}`;
        } else {
          const userObj = this.userInfo.data;
          const timeStamp = new Date().getTime();
          return `${userObj.id}#${userObj.name}#${newmd5}`;
        }
      } else {
        return str;
      }
    },
    // 拆分表格
    getSplitTableView() {
      this.cancelQuest();
      let params = JSON.parse(JSON.stringify(this.params));
      params.accountName = this.currentAccount.name;
      params.adGroupOptions.forEach((v) => {
        v.groupCriterion.target.locations = v.groupCriterion.target.locations.map((item) => {
          return {
            id: item.criteria_id,
            name: item.name_zhcn,
            code: item.country_code,
          };
        });
      });
      params.adOptions.forEach((v) => {
        if (v.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD') {
          // 行动号召自动的时候赋值为空
          if (!v.videoCallToActions.find((v) => v.key)) {
            v.videoCallToActions = null;
          }
          v.videoAssets = v.videoAssets.map((video) => {
            return {
              videoId: video.videoId,
              name: video.videoTitle,
              youtubeChannelId: video.youtubeChannelId,
              thumbnail: video.thumbnail,
            };
          });
        } else {
          // 行动号召自动的时候赋值为空
          if (!v.callToAction.key) {
            v.callToAction = null;
          }
        }
      });
      this.splitTableMsg = {
        name: '',
        adGroups: [],
      };

      this.splitViewLoading = true;
      createPreview(params, this)
        .then((res) => {
          this.splitViewLoading = false;
          if (res.code == 0) {
            this.splitTableMsg = res.data;
          } else {
            this.splitTableMsg = {
              name: '',
              adGroups: [],
            };
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
          } else {
            this.splitViewLoading = false;
            this.splitTableMsg = {
              name: '',
              adGroups: [],
            };
          }
        });
    },
    // 校验广告系列名称
    validatorName(rule, value, callback) {
      if (!value) {
        return callback('名称不能为空');
      }
      if (value.length > 375) {
        return callback('广告名称长度不能超过375字符');
      }
      callback();
    },
    // 点击创建
    creatAdsClick() {
      this.$refs.form.validate((validate) => {
        if (validate) {
          if (this.splitTableMsg.adGroups.length > 0) {
            this.$refs.campaignNameForm.validate((res) => {
              console.log(res);
              if (res) {
                this.$refs.splitTable.validate().then((res) => {
                  this.creatFormShow = true;
                });
              }
            });
          } else {
            this.$message({
              type: 'warning',
              message: '请重新进行拆分',
            });
          }
        }
      });
    },
    // 创建广告
    creatAd(params) {
      let lastParams = { ...this.splitTableMsg, ...params, ...this.formData };
      console.log(lastParams);
      this.$showLoading();
      createCampaign(lastParams)
        .then((res) => {
          this.$hideLoading();
          this.creatFormShow = false;
          if (res.code !== 0) {
            return;
          }
          this.$confirm('跳转到草稿箱，或者重新创建一个广告', '提示', {
            confirmButtonText: '再创建一个',
            cancelButtonText: '回到草稿箱',
          })
            .then((res) => {
              this.$parent.current = 1;
            })
            .catch((res) => {
              this.$router.push({
                name: 'googleAd',
              });
              this.$store.commit('googleAdActiveTab/setActiveTab', 'drafBox');
            });
        })
        .catch((res) => {
          this.$hideLoading();
        });
    },
  },
  created() {
    // this.getConfigCheck();
    // this.getSplitTableView();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.el-form-item .el-select .el-input__inner {
  padding-left: 90px;
}

.campaignName {
  display: flex;
  align-items: center;
  .label {
    flex: 100px 0 0;
  }
  .input {
    flex: 1;
  }
}
.trance {
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  height: 40px;
  border-right: none;
  margin-right: -3px;
  background-color: #f5f7fa;
  color: #909399;
}
</style>
